.continue-bg-above {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50vh;
  transform: translate3d(0, -100%, 0);
}
.continue-bg-above-with-logo {
}
.continue-bg-above-with-logo::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0px;
  width: 60px;
  height: 60px;
  background-image: url(/images/logo-colored-1.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  animation: rotater infinite 2s ease-in-out;
  animation-play-state: paused;
}
.continue-bg-above-refresh::after {
  animation-play-state: running;
}
@keyframes rotater {
  0% {
    transform: rotate(0deg) scale(1);
  }
  25% {
    transform: rotate(180deg) scale(1);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
  }
  75% {
    transform: rotate(180deg) scale(1);
  }
  100% {
    transform: rotate(0deg) scale(1);
  }
}
