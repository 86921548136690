.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 300px;
  height: 100vh;
  z-index: 2;
  overflow-y: auto;
  padding-bottom: 40px;
  overscroll-behavior: contain;
}
.sidebar-left {
  left: 0;
  box-shadow: 0.1em 0.5em 0.5em rgba(0, 0, 0, 0.6);
}
.sidebar-right {
  right: 0;
  box-shadow: 0.1em -0.5em 0.5em rgba(0, 0, 0, 0.6);
}
.sidebar-shadow {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}
.navbar {
  transform: translate3d(0, 0, 0);
  transition: transform 0.2s ease-in;
  top: 0;
  z-index: 1;
}
.navbar-hidden {
  transform: translate3d(0, -100%, 0);
}
.top-nav-icon {
  height: 20px;
}
.top-icon {
  height: 30px;
}
.nav-item:hover {
  text-decoration: none;
}
