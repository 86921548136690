// FORMS

.form-control:focus {
  caret-color: #6632b0;
  box-shadow: 0 0 0 0.2rem rgba(102, 50, 176, 0.44);
}
.form-control {
  border: none;
  border-bottom: 1px solid #dee2e6;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}
.form-control:focus {
  box-shadow: 0 0.1rem 0 0 rgba(102, 50, 176, 0.44);
}
.custom-check-container {
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.custom-check-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.custom-check {
  height: 20px;
  min-width: 20px;
  border: 2px solid #eee;
  border-radius: 2px;
  margin-right: 5px;
  transition: background-position 0.3s ease-in;
  background-size: 60px 60px;
  background-image: repeating-linear-gradient(
    to bottom,
    #fff,
    #fff 25%,
    #fd64b4 50%,
    #f5293e 100%
  );
}
.custom-check-container:hover input ~ .custom-check {
  background-position: 0 0;
}
.custom-check-container input:checked ~ .custom-check {
  background-position: 0 40px;
}
