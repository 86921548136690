.kyc-card {
  max-width: 600px;
}

.kyc-card .react-html5-camera-photo > video {
  width: 100%;
}

.kyc-card .react-html5-camera-photo > .display-error {
  width: 100%;
}

.kyc-card .react-html5-camera-photo > .display-error h1 {
  color: red;
  font-size: 1.5rem;
}

.display-error ~ video {
  display: none !important; /* need important to override inline style */
}

.display-error ~ #container-circles {
  display: none;
}

.kyc-container {
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
}

.kyc-container > p {
  font-size: 1rem;
}

.kyc-container > em {
  font-size: 0.8rem;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.dropzone:focus {
  border-color: #2196f3;
}

.dropzone.disabled {
  opacity: 0.6;
}
