.bottom-drawer {
  position: absolute;
  transform: translate3d(0, 100%, 0);
  opacity: 1;
  transition: opacity 0.3s ease-in, transform 0.3s ease-in;
  bottom: 0;
  left: 0;
  right: 0;
  width: 400px;
  max-width: 90%;
  z-index: 1060;
  margin: auto;
}

.bottom-drawer.show {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.modal-backdrop {
  cursor: pointer;
}

.modal-backdrop {
  opacity: 0;
  transform: translate3d(0, 100%, 0);
  transition: opacity 0.3s ease-in, transform 0s ease-in 0.3s;
}

.modal-backdrop.show {
  transform: translate3d(0, 0, 0);
  transition: opacity 0.3s ease-in;
}
