.origin-filter {
  transition: width 0.2s ease-in;
  overflow: hidden;
}

.w-0 {
  width: 0;
}

.right-0 {
  right: 0;
}

.left-0 {
  left: 0;
}
