// UTILITIES

h4 {
  letter-spacing: 0.1rem;
  font-weight: 100;
}

.overflow-hidden {
  overflow: hidden;
}

.scroll-x {
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

.scroll-y {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.text-wrap {
  white-space: normal;
}

.bottom-0 {
  bottom: 0;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-grow-3 {
  flex-grow: 3;
}

.clear-top {
  padding-top: 3rem;
}

.clear-bottom {
  height: 112px;
}

.cursor-pointer {
  cursor: pointer;
}

.single-line {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.nowrap {
  white-space: nowrap;
}

.bg-opaque {
  background-color: rgba(255, 255, 255, 0.4) !important;
}

.full-absolute {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.fill-available {
  height: -webkit-fill-available;
}

.opacity-0 {
  opacity: 0;
}

.hash-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  direction: rtl;
}

.text-capitalize-first::first-letter {
  text-transform: uppercase;
}

.close {
  color: currentColor;
}

@include media-breakpoint-up(sm) {
  .bg-sm-primary {
    background-color: var(--primary) !important;
  }
  .bg-sm-secondary {
    background-color: var(--secondary) !important;
  }
  .bg-sm-transparent {
    background-color: transparent !important;
  }
}

@include media-breakpoint-up(md) {
  .bg-md-primary {
    background-color: var(--primary) !important;
  }
  .bg-md-secondary {
    background-color: var(--secondary) !important;
  }
  .bg-md-transparent {
    background-color: transparent !important;
  }
  .bg-md-white {
    background-color: #ffffff !important;
  }
  .clear-top {
    padding-top: 5rem;
  }
}

@include media-breakpoint-up(lg) {
  .bg-lg-primary {
    background-color: var(--primary) !important;
  }
  .bg-lg-secondary {
    background-color: var(--secondary) !important;
  }
  .bg-lg-transparent {
    background-color: transparent !important;
  }
}

@include media-breakpoint-up(xl) {
  .bg-xl-primary {
    background-color: var(--primary) !important;
  }
  .bg-xl-secondary {
    background-color: var(--secondary) !important;
  }
  .bg-lg-transparent {
    background-color: transparent !important;
  }
}

@media all and (display-mode: standalone) {
  // Clear iOS status bar
  .bottom-bar {
    padding-bottom: 20px !important;
  }
}
