.staking-card {
  max-width: 600px;
}

.input-group-text {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:focus + .input-group-append .input-group-text {
  box-shadow: 0 0.1rem 0 0 rgba(102, 50, 176, 0.44);
  border-color: #583182;
}

.staking-nav-tab {
  cursor: pointer;
}

.staking-nav-tab-active {
  opacity: 1;
}

.staking-nav-tab-inactive {
  opacity: 0.7;
}

.staking-device-img {
  height: 50px;
  object-fit: contain;
}

.staking-devices-loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.7);
  z-index: 9;
}
