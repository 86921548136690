.logo-color-0 {
  fill: #9294c9;
}
.logo-color-1 {
  fill: #ec4e85;
}
.logo-color-2 {
  fill: #f27954;
}
.logo-color-3 {
  fill: #63a5d9;
}
.logo-color-4 {
  fill: #9b9ccd;
}
.logo-color-5 {
  fill: #9797ca;
}
