.text-muted.active {
  color: #6632b0 !important;
}

.bottom-bar {
  transform: translate3d(0, 0, 0);
  transition: transform 0.2s ease-in;
}

.bottom-bar-hidden {
  transform: translate3d(0, 100%, 0);
}

.bottom-icon {
  height: 20px;
}

.large-icon {
  height: 40px;
}

.small-icon {
  height: 20px;
}

.line-height-1 {
  height: 1em;
}
